import React from 'react';
import 'features/account/styles/Account.scss';
import { Box, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { Common } from 'config/utils';
import {
   checkoutErrorStore,
   setSubscriptionConfirmed,
   setSubscriptionWarningDialogOpen,
} from '../../stores/checkout-error.slice';
import OrderFrequency from 'features/order-bar/components/OrderFrequency';
import ConditionalRender from 'components/ConditionalRender';
import { companyStore } from 'features/company/stores/company.slice';

const SubscriptionWarningDialog = () => {
   const dispatch = useAppDispatch();
   const { companyInfo } = useAppSelector(companyStore);
   const { subscriptionWarningDialogOpen } = useAppSelector(checkoutErrorStore);

   const onConfirmClose = () => {
      dispatch(setSubscriptionWarningDialogOpen(false));
      dispatch(setSubscriptionConfirmed(false));
   };

   const handleConfirmSubscription = () => {
      dispatch(setSubscriptionWarningDialogOpen(false));
      dispatch(setSubscriptionConfirmed(true));
   };

   return (
      <ConfirmationDialog
         open={subscriptionWarningDialogOpen}
         onClose={onConfirmClose}
         confirmContent={
            <Stack direction="column" spacing={3}>
               <Box>
                  <ConditionalRender
                     condition={!!companyInfo.uiSettings?.subscriptionWarningHtml}
                     componentIfTrue={
                        <Typography variant="body2">
                           {Common.renderHtml(companyInfo.uiSettings.subscriptionWarningHtml)}
                        </Typography>
                     }
                     componentIfFalse={
                        <Typography variant="body2">
                           Your order includes <b>Weekly Subscription</b> items with automatic weekly charges. You can
                           manage or modify this subscription any time on your account page later. To continue, click{' '}
                           <b>Confirm</b>.
                        </Typography>
                     }
                  />
               </Box>
               <ConditionalRender
                  condition={companyInfo.uiSettings?.subscribeAllOnlyMode}
                  componentIfTrue={
                     <>
                        <Box>
                           <Typography variant="body2">
                              Prefer a <b>One-Time Order</b>? Change the <b>Frequency</b> before confirming.
                           </Typography>
                        </Box>
                        <Box>
                           <OrderFrequency standard />
                        </Box>
                     </>
                  }
               />
            </Stack>
         }
         title={'Confirm Subscription Order'}
         confirmBtnText={'Confirm'}
         isLoading={false}
         onConfirm={() => handleConfirmSubscription()}
         cancelBtnText="Cancel"
         onCancel={onConfirmClose}
      />
   );
};

export default SubscriptionWarningDialog;

import React, { useEffect, useRef, useState } from 'react';
import {
   Box,
   Container,
   Divider,
   FormControl,
   FormControlLabel,
   FormHelperText,
   Grid,
   ListItem,
   Stack,
   styled,
   Typography,
   useMediaQuery,
   useTheme,
} from '@mui/material';
import OrderDetails from './components/orderDetails/OrderDetails';
import PaymentDetails from './components/paymentDetails/PaymentDetails';
import OrderSummary from './components/orderSummary/OrderSummary';
import './styles/Checkout.scss';
import FeatureItems from './components/featureItems/FeatureItems';
import OrderLocation from './components/orderDetails/OrderLocation';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
   checkoutStore,
   postCheckoutAsync,
   resetPostCheckoutStatus,
   setCheckingOutMessage,
} from './stores/checkout.slice';
import { LoadStatus } from 'config/utils';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import clsx from 'clsx';
import FormButton from '../../components/FormButton';
import { companyStore } from '../company/stores/company.slice';
import { cartStore } from '../cart/stores/cart.slice';
import { checkoutShippingStore } from './stores/checkout-shipping.slice';
import OrderTotal from './components/orderTotal/OrderTotal';
import { SectionCard } from './components/SectionCard';
import Discounts from './components/discounts/Discounts';
import useHeaderSize from '../../app/useHeaderSize';
import { OrderType } from '../order/config/order.const';
import { IDelivery } from '../cart/types/cart.types';
import { accountStore } from '../account/stores/account.slice';
import ProfileHeader from '../profile/components/ProfileHeader';
import CustomFields from './components/customFields/CustomFields';
import MenuItem from '../menuItem/MenuItem';
import { menuItemStore } from '../menuItem/stores/menuItem.slice';
import SubscriptionWarningDialog from './components/orderTotal/SubscriptionWarningDialog';
import {
   checkoutErrorStore,
   setExistingSubscriptionWarningDialogOpen,
   setSubscriptionConfirmed,
   setSubscriptionWarningDialogOpen,
} from './stores/checkout-error.slice';
import ExistingSubscriptionWarningDialog from './components/orderTotal/ExistingSubscriptionWarningDialog';
import { subscriptionStore } from '../subscription/stores/subscriptions.slice';

interface ICompleteOrderValues {
   acknowledge?: boolean;
}
const schema = yup.object().shape({
   acknowledge: yup.boolean().oneOf([true], 'acknowledgement is required'),
});
const ackName = 'acknowledge';

export const StyledCheckoutContainer = styled(Container)(({ theme }) => ({
   [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
   },
   [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
   },
}));

const Checkout = () => {
   const dispatch = useAppDispatch();
   const theme = useTheme();
   const navigate = useNavigate();
   const { addressRuleError, hasSelectedAddressRuleError } = useAppSelector(checkoutShippingStore);
   const { shippingProfiles, billingProfiles } = useAppSelector(accountStore);
   const {
      featuredProducts,
      postCheckStatus,
      checkoutLoadStatus,
      paymentError,
      deliveryRuleError,
      cartRuleError,
      completedCartId,
   } = useAppSelector(checkoutStore);
   const { companyInfo } = useAppSelector(companyStore);
   const { openMenuItem } = useAppSelector(menuItemStore);
   const {
      subscriptionWarningDialogOpen,
      subscriptionConfirmed,
      existingSubscriptionWarningDialogOpen,
      existingSubscriptionConfirmed,
   } = useAppSelector(checkoutErrorStore);
   const { cart } = useAppSelector(cartStore);
   const { userSubscriptions } = useAppSelector(subscriptionStore);
   const isTabletScreen = useMediaQuery(theme.breakpoints.down('md'));
   const isDesktopScreen = useMediaQuery(theme.breakpoints.up('lg'));
   const isLargeScreenDown = useMediaQuery(theme.breakpoints.down('lg'));
   const [headerSize, headerContainerRef, headerMainHeight, , hasFixedHeader] = useHeaderSize();
   const orderDetailCardRef = React.useRef<HTMLDivElement>(null);
   const orderLocationCardRef = React.useRef<HTMLDivElement>(null);
   const paymentDetailsCardRef = React.useRef<HTMLDivElement>(null);
   const orderSummaryCardRef = React.useRef<HTMLDivElement>(null);
   const acknowledgeCardRef = React.useRef<HTMLDivElement>(null);
   const [disabled, setDisabled] = useState(false);
   const isLoading = postCheckStatus === LoadStatus.loading || checkoutLoadStatus === LoadStatus.loading;
   const foundCartTotal = cart?.totals?.find((cartTotalItem) => cartTotalItem.name === 'Total');
   const cartTotal = foundCartTotal ? foundCartTotal.amount : 0;
   const foundCartWeeklyTotal = cart?.totals?.find((cartTotalItem) => cartTotalItem.name === 'Weekly Total');
   const foundCartWeeklySubTotal = cart?.totals?.find((cartTotalItem) => cartTotalItem.name === 'Weekly Subtotal');
   const cartWeeklyTotal = foundCartWeeklyTotal ? foundCartWeeklyTotal.amount : 0;
   const leftContainerRef = useRef<HTMLDivElement>(null);
   const completeOrderBtnRef = useRef<HTMLDivElement>(null);
   const [leftContainerHeight, setLeftContainerHeight] = useState(0);
   const hasActiveSubscription = userSubscriptions.some((subscription) => subscription.status === 'active');

   const shouldUseWeeklyAmount = () => {
      return cartTotal === 0 && cartWeeklyTotal > 0;
   };

   const getCheckoutAmount = () => {
      const total = shouldUseWeeklyAmount() ? cartWeeklyTotal : cartTotal;
      return total.toLocaleString('en-US', {
         style: 'currency',
         currency: 'USD',
      });
   };

   const requireAcknowledgement = companyInfo.uiSettings.checkoutAcknowledgement;

   const hasDeliveryMethodError =
      cart.deliveries && cart.deliveries.length > 0 && cart.deliveries[0].shippingLocationId === null;

   const delivery = cart.deliveries.length ? cart.deliveries[0] : ({} as IDelivery);
   const foundShippingProfile = shippingProfiles?.find((findProfile) => findProfile.id === delivery.shippingProfileId);
   const emptyDeliveryAddress =
      delivery?.shippingLocationType === OrderType.homeDelivery &&
      (delivery.shippingProfileId === null || !foundShippingProfile);

   const foundBillingProfile = billingProfiles?.find((findProfile) => findProfile.id === cart?.billingProfileId);
   const emptyPaymentMethod =
      (cart?.billingProfileId === null || !foundBillingProfile) && (cartTotal > 0 || cartWeeklyTotal > 0);
   const missingDeliverInformation =
      delivery?.shippingLocationType === OrderType.pickup &&
      (!delivery?.shippingFirstName || !delivery?.shippingLastName || !delivery?.shippingPhone);

   const methods = useForm<ICompleteOrderValues>({
      defaultValues: { acknowledge: true },
      resolver: yupResolver(schema),
   });

   const {
      register,
      formState: { errors },
      setValue,
   } = methods;

   const displayErrorMessage = errors[ackName] ? String(errors[ackName]?.message) : '';
   const hasAckError = !!errors[ackName];

   const onPostCheckStatus = () => {
      if (postCheckStatus === LoadStatus.complete || postCheckStatus === LoadStatus.failed) {
         dispatch(resetPostCheckoutStatus());

         if (paymentError.length !== 0) {
            window.scrollTo(0, 0);
         }
         if (postCheckStatus === LoadStatus.complete) {
            navigate(`/confirmation?cartId=${completedCartId}`);
         }
      }
   };

   const confirmedCompleteOrder = () => {
      if (companyInfo?.finixObject?.env && companyInfo?.finixObject?.mid) {
         setDisabled(true);
         window.Finix.Auth(companyInfo.finixObject.env, companyInfo.finixObject.mid, (sessionId: string) => {
            dispatch(setSubscriptionConfirmed(false));
            dispatch(setCheckingOutMessage('Processing order…'));
            dispatch(postCheckoutAsync({ sessionId }));
            setDisabled(false);
         });
      }
   };

   const onCompleteOrder = () => {
      if (foundCartWeeklySubTotal && hasActiveSubscription) {
         dispatch(setExistingSubscriptionWarningDialogOpen(true));
      } else if (foundCartWeeklySubTotal) {
         dispatch(setSubscriptionWarningDialogOpen(true));
      } else {
         confirmedCompleteOrder();
      }
   };

   const getErrorsPosition = (skipPayment?: boolean) => {
      let topPosition = -1;
      if ((deliveryRuleError.length > 0 || hasDeliveryMethodError) && orderDetailCardRef.current) {
         topPosition = orderDetailCardRef.current.getBoundingClientRect().top + window.scrollY - headerMainHeight - 20;
      } else if (
         ((addressRuleError.length > 0 && hasSelectedAddressRuleError) ||
            emptyDeliveryAddress ||
            missingDeliverInformation) &&
         orderLocationCardRef.current
      ) {
         topPosition =
            orderLocationCardRef.current.getBoundingClientRect().top + window.scrollY - headerMainHeight - 20;
      } else if ((emptyPaymentMethod || (!skipPayment && paymentError.length > 0)) && paymentDetailsCardRef.current) {
         topPosition =
            paymentDetailsCardRef.current.getBoundingClientRect().top + window.scrollY - headerMainHeight - 20;
      } else if (cartRuleError.length > 0 && orderSummaryCardRef.current) {
         topPosition = orderSummaryCardRef.current.getBoundingClientRect().top + window.scrollY - headerMainHeight - 20;
      } else if (hasAckError && acknowledgeCardRef.current) {
         topPosition = acknowledgeCardRef.current.getBoundingClientRect().top + window.scrollY - headerMainHeight - 20;
      }

      return topPosition;
   };

   const onSubmit = (data: ICompleteOrderValues) => {
      if (!requireAcknowledgement || (requireAcknowledgement && data?.acknowledge)) {
         const topPosition = getErrorsPosition(true);
         if (topPosition === -1) {
            onCompleteOrder();
         }
      }
   };

   useEffect(() => {
      if (subscriptionConfirmed || existingSubscriptionConfirmed) {
         confirmedCompleteOrder();
      }
   }, [subscriptionConfirmed, existingSubscriptionConfirmed]);

   useEffect(() => {
      const topPosition = getErrorsPosition(true);
      if (topPosition > -1) {
         window.scrollTo({ top: topPosition, behavior: 'smooth' });
      }
   }, [methods.formState.errors, methods.formState.isSubmitted]);

   useEffect(() => {
      if (companyInfo.uiSettings.checkoutAcknowledgement) {
         setValue('acknowledge', false);
      }
   }, [companyInfo.uiSettings.checkoutAcknowledgement]);

   useEffect(() => {
      onPostCheckStatus();
   }, [postCheckStatus]);

   useEffect(() => {
      const topPosition = getErrorsPosition();
      if (topPosition > -1) {
         window.scrollTo({ top: topPosition, behavior: 'smooth' });
      } else if (topPosition === -1) {
         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
      if (leftContainerRef.current) {
         setLeftContainerHeight(leftContainerRef.current.clientHeight);
      }
   }, [
      deliveryRuleError,
      addressRuleError,
      paymentError,
      cartRuleError,
      hasDeliveryMethodError,
      emptyDeliveryAddress,
      emptyPaymentMethod,
      missingDeliverInformation,
      hasSelectedAddressRuleError,
      hasAckError,
      leftContainerRef?.current?.clientHeight,
   ]);

   return (
      <>
         <StyledCheckoutContainer maxWidth="xl" sx={{ padding: '1px 0px 60px 0' }}>
            <Grid
               container
               spacing={isDesktopScreen ? 3 : 1}
               position="relative"
               pb={1}
               pt={2}
               mt={0}
               sx={{
                  top: headerSize?.height
                     ? headerSize.height + (isLargeScreenDown || hasFixedHeader ? headerMainHeight : 0)
                     : 0,
                  backgroundColor: theme.palette.background.default,
               }}
               style={{ paddingTop: '4px', paddingBottom: `${completeOrderBtnRef?.current?.clientHeight ?? 0}px` }}
            >
               <Grid item xs={12} style={{ paddingTop: '4px', paddingRight: '12px' }} ref={headerContainerRef}>
                  <ProfileHeader paddingBottom={0} />
               </Grid>
               <Grid item xs={12} sm={isTabletScreen ? 12 : 7} style={{ paddingTop: '4px' }}>
                  <Stack ref={leftContainerRef} direction="column" spacing={2} m={1}>
                     <OrderDetails
                        orderDetailCardRef={orderDetailCardRef}
                        hasDeliveryMethodError={hasDeliveryMethodError}
                     />
                     {!hasDeliveryMethodError && (
                        <OrderLocation
                           orderLocationCardRef={orderLocationCardRef}
                           emptyDeliveryAddress={emptyDeliveryAddress}
                           missingDeliverInformation={missingDeliverInformation}
                        />
                     )}
                     {!hasDeliveryMethodError && (
                        <PaymentDetails
                           paymentDetailsCardRef={paymentDetailsCardRef}
                           emptyPaymentMethod={emptyPaymentMethod}
                        />
                     )}
                     {!hasDeliveryMethodError && <CustomFields />}
                     {featuredProducts.length > 0 ? <FeatureItems /> : null}
                  </Stack>
               </Grid>
               <Grid item xs={12} sm={isTabletScreen ? 12 : 5} style={{ paddingTop: '4px' }}>
                  <Stack
                     display="block"
                     direction="column"
                     spacing={2}
                     m={1}
                     sx={{
                        minHeight: isTabletScreen ? '600px' : '1100px',
                        height: isTabletScreen ? 'auto' : `${leftContainerHeight + 15}px`,
                        overflowY: 'auto',
                     }}
                  >
                     <SectionCard>
                        <Stack direction="column" spacing={2}>
                           <OrderSummary orderSummaryCardRef={orderSummaryCardRef} />
                           <Divider
                              sx={{ background: theme.palette.background.default }}
                              style={{ marginTop: 0, borderBottomWidth: 'thin' }}
                           />
                           <Discounts />
                           <Divider
                              sx={{ background: theme.palette.background.default }}
                              style={{ marginTop: 0, borderBottomWidth: 'thin' }}
                           />
                           <OrderTotal />
                        </Stack>
                     </SectionCard>
                     <SectionCard ref={acknowledgeCardRef} hasError={hasAckError} hide={!requireAcknowledgement}>
                        <FormProvider {...methods}>
                           <form onSubmit={methods.handleSubmit(onSubmit)}>
                              {requireAcknowledgement ? (
                                 <ListItem>
                                    <Box mt={2} mb={1}>
                                       <FormControl error={hasAckError}>
                                          <FormControlLabel
                                             control={
                                                <Checkbox
                                                   {...register(ackName)}
                                                   color="primary"
                                                   sx={{
                                                      color: hasAckError ? 'error.main' : 'text.primary',
                                                      '&.Mui-checked': {
                                                         color: hasAckError ? 'error.main' : 'text.primary',
                                                      },
                                                   }}
                                                />
                                             }
                                             label="** By checking the box on the left, you will receive text updates about the status of your
                                 order or your recurring meals. These updates will only include essential information and will
                                 not contain any marketing materials."
                                             sx={{
                                                '& .MuiFormControlLabel-label': {
                                                   fontSize: '.75rem',
                                                },
                                             }}
                                          />
                                          {hasAckError && <FormHelperText>{displayErrorMessage}</FormHelperText>}
                                       </FormControl>
                                    </Box>
                                 </ListItem>
                              ) : null}
                              <ListItem>
                                 <Grid item xs={12}>
                                    <Box ref={completeOrderBtnRef} className={clsx({ 'place-order-fixed-btn': true })}>
                                       <FormButton
                                          label={
                                             <Stack
                                                spacing={1}
                                                alignItems="center"
                                                width="100%"
                                                justifyContent="center"
                                                direction="row"
                                             >
                                                <Typography variant="body1" component="span">
                                                   Complete Order {shouldUseWeeklyAmount() ? '(Weekly)' : ''}
                                                </Typography>
                                                <Typography variant="body1" component="span">
                                                   {getCheckoutAmount()}
                                                </Typography>
                                             </Stack>
                                          }
                                          isLoading={isLoading || disabled}
                                          onClick={methods.handleSubmit(onSubmit)}
                                       />
                                    </Box>
                                 </Grid>
                              </ListItem>
                           </form>
                        </FormProvider>
                     </SectionCard>
                  </Stack>
               </Grid>
            </Grid>
         </StyledCheckoutContainer>
         <MenuItem open={openMenuItem} />
         {subscriptionWarningDialogOpen && <SubscriptionWarningDialog />}
         {existingSubscriptionWarningDialogOpen && <ExistingSubscriptionWarningDialog />}
      </>
   );
};

export default Checkout;

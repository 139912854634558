import React, { useEffect } from 'react';
import Checkout from 'features/checkout/Checkout';
import {
   convertInitCheckoutEventData,
   convertInitCheckoutPixelEventData,
   convertPageViewEventData,
   trackMptEvent,
   trackPixelEvent,
} from 'features/order/utils/event.helper';
import { TrackEventType, TrackPixelType } from 'features/order/types/event.types';
import {
   cartStore,
   resetChangeCartLoadStatus,
   resetFetchCart,
   resetPostAddRemoveStatus,
} from 'features/cart/stores/cart.slice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { handleFooterElementDisplay, handleMainElementDisplay, LoadStatus } from '../config/utils';
import {
   checkoutStore,
   fetchCheckoutAsync,
   resetCheckoutLoadStatus,
   resetUpdateBillingProfileStatus,
} from 'features/checkout/stores/checkout.slice';
import { useNavigate } from 'react-router-dom';
import useUserCart from 'app/useUserCart';
import { orderStore, resetDeleteDeliveryDatesStatus } from 'features/order/stores/order.slice';
import { getHomeDeliveryShippingProfile } from 'features/checkout/utils/checkout.helper';
import {
   checkoutShippingStore,
   presetShippingProfileAsync,
   resetPresetShippingProfileStatus,
   resetUpdateShippingProfileStatus,
   setAddDeliveryDialogOpen,
   setDeliveryDialogOpen,
   setOverrideWithDefault,
} from 'features/checkout/stores/checkout-shipping.slice';
import FullPageLoader from 'components/FullPageLoader';
import { accountStore } from 'features/account/stores/account.slice';
import { setCardLoading } from '../features/order/stores/order-load.slice';
import { setCardLoading as setCheckoutCardLoading } from '../features/checkout/stores/checkout-load.slice';
import { productStore } from '../features/order/stores/product.slice';
import { packageStore, resetFetchPackagesStatus } from '../features/package/stores/package.slice';
import { fetchUserSubscriptionsAsync } from '../features/subscription/stores/subscriptions.slice';

export default function CheckoutPage() {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const { fetchPackagesStatus } = useAppSelector(packageStore);
   const { shopTasksStatus } = useAppSelector(productStore);
   const { checkoutLoadStatus, postCheckStatus, checkingOutMessage } = useAppSelector(checkoutStore);
   const {
      addDeliveryDialogOpen,
      addressRuleError,
      updateShippingProfileStatus,
      updateBillingProfileStatus,
      presetShippingProfileStatus,
   } = useAppSelector(checkoutShippingStore);
   const { shippingProfiles } = useAppSelector(accountStore);
   const {
      cart,
      fetchCartResponse,
      fetchCartStatus,
      changeCartLoadStatus,
      postAddRemoveStatus,
      postSubscribeAllStatus,
   } = useAppSelector(cartStore);
   const { deleteDeliveryDatesStatus } = useAppSelector(orderStore);
   useUserCart({ fetchCart: true, fetchShopTasks: true });
   const isLoading =
      postSubscribeAllStatus === LoadStatus.loading ||
      fetchPackagesStatus === LoadStatus.loading ||
      fetchCartStatus === LoadStatus.loading ||
      checkoutLoadStatus === LoadStatus.loading ||
      postCheckStatus === LoadStatus.loading ||
      presetShippingProfileStatus === LoadStatus.loading;

   const presetHomeDeliveryShippingProfile = () => {
      // Going to check if the checkout is a home delivery and need a shipping profile to be set correctly

      const shippingProfileToUse = getHomeDeliveryShippingProfile(cart, shippingProfiles);

      if (shippingProfileToUse !== null) {
         dispatch(
            presetShippingProfileAsync({
               shippingLocationId: shippingProfileToUse.shippingLocationId,
               shippingProfileId: shippingProfileToUse.id,
               updatePresets: true,
            }),
         );

         return true;
      }

      return false;
   };

   const possibleNavBackToOrder = () => {
      if (cart?.deliveries) {
         if (cart.deliveries.every((delivery) => !delivery?.products || delivery.products.length === 0)) {
            navigate('/order');
         }
      }
   };

   useEffect(() => {
      if (cart?.deliveries && cart.deliveries.length > 0) {
         possibleNavBackToOrder();
      }
   }, [cart?.deliveries]);

   useEffect(() => {
      if (checkoutLoadStatus === LoadStatus.failed) {
         dispatch(resetCheckoutLoadStatus());
         navigate('/order');
      } else if (checkoutLoadStatus === LoadStatus.complete) {
         dispatch(resetCheckoutLoadStatus());
         const ranReset = presetHomeDeliveryShippingProfile();
         if (!ranReset) {
            dispatch(setCheckoutCardLoading(false));
         }
      }
   }, [checkoutLoadStatus]);

   useEffect(() => {
      if (presetShippingProfileStatus === LoadStatus.complete) {
         dispatch(resetPresetShippingProfileStatus());
         dispatch(setCheckoutCardLoading(false));
      }
   }, [presetShippingProfileStatus]);

   useEffect(() => {
      if (updateShippingProfileStatus === LoadStatus.complete) {
         dispatch(resetUpdateShippingProfileStatus());

         if (addDeliveryDialogOpen) {
            dispatch(setAddDeliveryDialogOpen(false));
            const foundDefault = shippingProfiles.find((profile) => profile.default);

            if (foundDefault && addressRuleError.find((error) => error.id === foundDefault.id)) {
               dispatch(setOverrideWithDefault(true));
               dispatch(setDeliveryDialogOpen(true));
            }
         }
      }
   }, [updateShippingProfileStatus]);

   useEffect(() => {
      if (updateBillingProfileStatus === LoadStatus.complete) {
         dispatch(resetUpdateBillingProfileStatus());
         // TODO This is a reminding to implement custom error with id with billing similar to shipping
         // if (addDeliveryDialogOpen) {
         //    dispatch(setAddDeliveryDialogOpen(false));
         //    const foundDefault = shippingProfiles.find((profile) => profile.default);
         //
         //    if (foundDefault && addressRuleError.find((error) => error.id === foundDefault.id)) {
         //       dispatch(setOverrideWithDefault(true));
         //       dispatch(setDeliveryDialogOpen(true));
         //    }
         // }
      }
   }, [updateBillingProfileStatus]);

   useEffect(() => {
      if (changeCartLoadStatus === LoadStatus.complete) {
         dispatch(resetChangeCartLoadStatus());
         possibleNavBackToOrder();
      }
   }, [changeCartLoadStatus]);

   useEffect(() => {
      if (postAddRemoveStatus === LoadStatus.complete) {
         dispatch(resetPostAddRemoveStatus());
         possibleNavBackToOrder();
      }
   }, [postAddRemoveStatus]);

   useEffect(() => {
      if (deleteDeliveryDatesStatus === LoadStatus.complete) {
         dispatch(resetDeleteDeliveryDatesStatus());
         possibleNavBackToOrder();
      }
   }, [deleteDeliveryDatesStatus]);

   useEffect(() => {
      if (fetchCartStatus === LoadStatus.complete && fetchCartResponse.success) {
         dispatch(resetFetchCart());

         if (fetchCartResponse.cart && cart?.deliveries) {
            if (cart.deliveries.every((delivery) => !delivery?.products || delivery.products.length === 0)) {
               navigate('/order');
            } else {
               dispatch(fetchCheckoutAsync());
               trackMptEvent(TrackEventType.pageView, convertPageViewEventData());
               trackMptEvent(TrackEventType.initCheckout, convertInitCheckoutEventData(fetchCartResponse));
               trackPixelEvent(TrackPixelType.initiateCheckout, convertInitCheckoutPixelEventData(fetchCartResponse));
            }
         } else {
            navigate('/order');
         }
      }
   }, [fetchCartResponse]);

   useEffect(() => {
      if (shopTasksStatus === LoadStatus.complete) {
         dispatch(setCardLoading(false));
      }
   }, [shopTasksStatus]);

   useEffect(() => {
      if (fetchPackagesStatus === LoadStatus.complete) {
         dispatch(resetFetchPackagesStatus());
      }
   }, [fetchPackagesStatus]);

   useEffect(() => {
      dispatch(fetchUserSubscriptionsAsync({}));
      dispatch(setCheckoutCardLoading(true));
      dispatch(setCardLoading(true));
      handleMainElementDisplay(false);
      handleFooterElementDisplay(false);
   }, []);

   return (
      <>
         <FullPageLoader loading={isLoading} message={checkingOutMessage} />
         <Checkout />
      </>
   );
}

import * as React from 'react';

interface IConditionalRender {
   condition: boolean;
   componentIfTrue: React.ReactNode | null;
   componentIfFalse?: React.ReactNode | null;
}

const ConditionalRender = ({
   condition,
   componentIfTrue,
   componentIfFalse,
}: IConditionalRender): JSX.Element | null => {
   return <>{condition ? componentIfTrue : componentIfFalse ? componentIfFalse : null}</>;
};

export default ConditionalRender;

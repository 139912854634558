import React, { useEffect } from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import 'features/order/styles/ProductList.scss';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
   packageStore,
   setOpenPackageItem,
   setPackageId,
   setPackageMenuMode,
   setSelectedPackage,
} from './stores/package.slice';
import PackageCardVertical from './components/PackageCardVertical';
import PackageCardHorizontal from './components/PackageCardHorizontal';
import { orderStore, resetPostDeliveryDateStatus } from 'features/order/stores/order.slice';
import { Common, LoadStatus } from 'config/utils';
import { useNavigate } from 'react-router-dom';
import { setOrderDialogOpen, setOrderDialogOption } from 'features/order/stores/order-bar.slice';
import { CustomContainer } from 'features/order/components/CustomContainer';
import { companyStore } from 'features/company/stores/company.slice';
import SkeletonLoader from 'components/SkeletonLoader';
import { cartStore } from 'features/cart/stores/cart.slice';
import { menuItemStore } from '../menuItem/stores/menuItem.slice';
import PackageItem from './components/PackageItem';
import useHeaderSize from 'app/useHeaderSize';
import { adjustHeaderTop } from 'features/order/utils/prduct-list.helper';
import useScreenSize from 'app/useScreenSize';
import ProfileHeader from 'features/profile/components/ProfileHeader';
import MenuItem from '../menuItem/MenuItem';
import { MptLink } from '../../components/MptLink';

const Package = () => {
   const theme = useTheme();
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const isLargeScreenDown = useMediaQuery(theme.breakpoints.down('lg'));
   const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
   const [headerSize, headerContainerRef, headerMainHeight, , hasFixedHeader] = useHeaderSize();
   const screenSize = useScreenSize();
   const productView = useMediaQuery(theme.breakpoints.down('sm')) ? 'list' : 'card';
   const { openMenuItem } = useAppSelector(menuItemStore);
   const { packages, openPackageItem } = useAppSelector(packageStore);
   const { postDeliveryDateStatus } = useAppSelector(orderStore);
   const { fetchCartStatus, cart } = useAppSelector(cartStore);
   const { companyInfo } = useAppSelector(companyStore);
   const isLoading = fetchCartStatus === LoadStatus.loading;
   const containerTop =
      typeof headerSize?.height !== 'undefined'
         ? headerSize.height + (isLargeScreenDown || hasFixedHeader ? headerMainHeight : 0)
         : 0;
   const handlePackageSelection = (inputPackageId: string) => {
      const selectedPackage = packages?.find((findPackage) => findPackage.id === inputPackageId);
      if (selectedPackage) {
         dispatch(setOpenPackageItem(false));
         dispatch(setPackageId(selectedPackage.id));
         dispatch(setOrderDialogOption('change-package'));
         dispatch(setOrderDialogOpen(true));
      }
   };

   const handlePackageView = (inputPackageId: string) => {
      const selectedPackage = packages?.find((findPackage) => findPackage.id === inputPackageId);
      dispatch(setSelectedPackage(selectedPackage));
      dispatch(setPackageMenuMode('main'));
      dispatch(setOpenPackageItem(true));
   };

   const onPostDeliveryDateStatus = () => {
      if (postDeliveryDateStatus === LoadStatus.complete) {
         dispatch(resetPostDeliveryDateStatus());
         dispatch(setOrderDialogOpen(false));
         navigate('/order');
      }
   };

   useEffect(() => {
      onPostDeliveryDateStatus();
   }, [postDeliveryDateStatus]);

   useEffect(() => {
      adjustHeaderTop(headerContainerRef, isLargeScreenDown);
   }, [screenSize]);

   useEffect(() => {
      adjustHeaderTop(headerContainerRef, isLargeScreenDown);
   }, [headerContainerRef]);

   return (
      <>
         <CustomContainer maxWidth={false}>
            <Grid
               container
               display="contents"
               sx={{ backgroundColor: theme.palette.background.default }}
               className="menu-container"
               ref={headerContainerRef}
            >
               <Grid
                  container
                  className="product-card-container"
                  spacing={2}
                  justifyContent="center"
                  sx={{
                     backgroundColor: theme.palette.background.default,
                     marginLeft: undefined,
                     marginTop: theme.spacing(1),
                     marginBottom: theme.spacing(12),
                     top: containerTop === 0 && !hasFixedHeader ? 90 : containerTop,
                  }}
               >
                  {cart.deliveries.length > 0 && cart.deliveries[0].packageid && (
                     <Grid item xs={12} style={{ paddingTop: '4px', paddingRight: '12px' }} ref={headerContainerRef}>
                        <ProfileHeader paddingBottom={0} linkText="Resume your current order" />
                     </Grid>
                  )}

                  <SkeletonLoader
                     loading={isLoading}
                     skeletonProps={{ width: '100%', height: '0px' }}
                     sx={{ marginLeft: '4px' }}
                  >
                     <Grid item xs={12}>
                        <Typography className="category-header" variant="h4" color={theme.palette.primary.contrastText}>
                           {companyInfo.uiSettings?.shopPageTitle
                              ? companyInfo.uiSettings?.shopPageTitle
                              : 'One-Time or Weekly Order'}
                        </Typography>
                     </Grid>
                     <Grid item xs={12} style={{ paddingTop: 0 }}>
                        <Typography variant="h6" textAlign="center">
                           Have a subscription? Click{' '}
                           <MptLink onClick={() => navigate('/user#subscriptions')} customColor="#c64f3f">
                              here
                           </MptLink>{' '}
                           to update your plan.
                        </Typography>
                     </Grid>
                  </SkeletonLoader>

                  <Grid
                     sx={{ padding: '0px 10px 30px 10px' }}
                     container
                     display="flex"
                     spacing={2}
                     mt={1}
                     ml={0.5}
                     justifyContent="center"
                  >
                     {companyInfo.uiSettings?.shopPageHtml && (
                        <Grid item xs={12} justifyContent="center">
                           <Box maxWidth="1020px" m="auto">
                              <Typography variant="body1" color={theme.palette.primary.contrastText}>
                                 {Common.renderHtml(companyInfo.uiSettings.shopPageHtml)}
                              </Typography>
                           </Box>
                        </Grid>
                     )}
                     {packages?.map((item) => {
                        return isLargeScreen ? (
                           <Grid
                              item
                              xs={12}
                              sm={productView === 'card' ? 3 : 4}
                              md={productView === 'card' ? 3 : 4}
                              key={item.id}
                              data-product-id={item.id}
                           >
                              {productView === 'card' ? (
                                 <PackageCardVertical
                                    {...{ item, handlePackageSelection, handlePackageView, addToCartText: 'Select' }}
                                 />
                              ) : (
                                 <PackageCardHorizontal {...{ item, handlePackageSelection, handlePackageView }} />
                              )}
                           </Grid>
                        ) : (
                           <Grid item xs={12} sm={6} md={4} key={item.id} data-product-id={item.id}>
                              {productView === 'card' ? (
                                 <PackageCardVertical
                                    {...{ item, handlePackageSelection, handlePackageView, addToCartText: 'Select' }}
                                 />
                              ) : (
                                 <PackageCardHorizontal {...{ item, handlePackageSelection, handlePackageView }} />
                              )}
                           </Grid>
                        );
                     })}
                  </Grid>
               </Grid>
            </Grid>
            <PackageItem open={openPackageItem} />
            <MenuItem open={openMenuItem} />
         </CustomContainer>
      </>
   );
};

export default Package;

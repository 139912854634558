import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

export enum LoadStatus {
   idle = 'idle',
   loading = 'loading',
   failed = 'failed',
   complete = 'complete',
}

export const Common = {
   renderHtml: (data: string | undefined) => {
      if (!data) return '';
      const sanitizedData = DOMPurify.sanitize(data);
      return parse(sanitizedData);
   },
};

export const handleMainElementDisplay = (display: boolean) => {
   const mainElem = document.getElementById('main');
   if (mainElem) {
      mainElem.style.display = display ? 'block' : 'none';
   }
};

export const handleFooterElementDisplay = (display: boolean) => {
   const mainElem = document.getElementById('footer');
   if (mainElem) {
      mainElem.style.display = display ? 'block' : 'none';
   }

   const wrapperElems = document.getElementsByClassName('footer-wrapper');
   if (wrapperElems && wrapperElems.length > 0) {
      Array.from(wrapperElems).forEach((elem) => {
         (elem as HTMLElement).style.display = display ? 'block' : 'none';
      });
   }
};

import React from 'react';
import 'features/account/styles/Account.scss';
import { Box, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ConfirmationDialog from 'components/ConfirmationDialog';
import {
   checkoutErrorStore,
   setExistingSubscriptionConfirmed,
   setExistingSubscriptionWarningDialogOpen,
} from '../../stores/checkout-error.slice';
import { MptLink } from 'components/MptLink';
import { useNavigate } from 'react-router-dom';

const ExistingSubscriptionWarningDialog = () => {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const { existingSubscriptionWarningDialogOpen } = useAppSelector(checkoutErrorStore);

   const onConfirmClose = () => {
      dispatch(setExistingSubscriptionWarningDialogOpen(false));
      dispatch(setExistingSubscriptionConfirmed(false));
   };

   const handleConfirmSubscription = () => {
      dispatch(setExistingSubscriptionWarningDialogOpen(false));
      dispatch(setExistingSubscriptionConfirmed(true));
   };

   const onGotoSubscriptions = () => {
      onConfirmClose();
      navigate('/user#subscriptions');
   };

   return (
      <ConfirmationDialog
         open={existingSubscriptionWarningDialogOpen}
         onClose={onConfirmClose}
         confirmContent={
            <Stack direction="column" spacing={3}>
               <Box>
                  <Typography variant="body2">
                     You already have an active <b>Subscription</b>. To continue with new Subscription order, click{' '}
                     <b>Confirm</b>. Alternatively, click{' '}
                     <b>
                        <MptLink sx={{ cursor: 'pointer', fontWeight: 600 }} onClick={onGotoSubscriptions}>
                           HERE
                        </MptLink>
                     </b>{' '}
                     to manage or modify your current Subscriptions.
                  </Typography>
               </Box>
            </Stack>
         }
         title={'Confirm Subscription Order'}
         confirmBtnText={'Confirm'}
         isLoading={false}
         onConfirm={() => handleConfirmSubscription()}
         cancelBtnText="Cancel"
         onCancel={onConfirmClose}
      />
   );
};

export default ExistingSubscriptionWarningDialog;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { LoadStatus } from 'config/utils';

interface CheckoutErrorState {
   status: LoadStatus;
   subscriptionWarningDialogOpen: boolean;
   subscriptionConfirmed: boolean;
   existingSubscriptionWarningDialogOpen: boolean;
   existingSubscriptionConfirmed: boolean;
}

const initialState: CheckoutErrorState = {
   status: LoadStatus.idle,
   subscriptionWarningDialogOpen: false,
   subscriptionConfirmed: false,
   existingSubscriptionWarningDialogOpen: false,
   existingSubscriptionConfirmed: false,
};

const checkoutErrorSlice = createSlice({
   name: 'checkoutError',
   initialState,
   reducers: {
      setSubscriptionWarningDialogOpen: (state, action: PayloadAction<boolean>) => {
         state.subscriptionWarningDialogOpen = action.payload;
      },
      setSubscriptionConfirmed: (state, action: PayloadAction<boolean>) => {
         state.subscriptionConfirmed = action.payload;
      },
      setExistingSubscriptionWarningDialogOpen: (state, action: PayloadAction<boolean>) => {
         state.existingSubscriptionWarningDialogOpen = action.payload;
      },
      setExistingSubscriptionConfirmed: (state, action: PayloadAction<boolean>) => {
         state.existingSubscriptionConfirmed = action.payload;
      },
   },
});

export const {
   setSubscriptionWarningDialogOpen,
   setSubscriptionConfirmed,
   setExistingSubscriptionWarningDialogOpen,
   setExistingSubscriptionConfirmed,
} = checkoutErrorSlice.actions;

export const checkoutErrorStore = (state: RootState) => state.checkoutErrorStore;

export default checkoutErrorSlice.reducer;
